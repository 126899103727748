import React from "react";
import InfoSection from "../../../utilities/InfoSection";
import LoreNavigation from "../../../utilities/LoreNavigation";

const sections = [
    {
        title: "A country occupied by Helsteig",
        content: `<p>Freimont is a poor country, comprised mostly of small farming towns, it relies on its agriculture to bolster its economy.</p><p>Freimont is comprised mostly of small poor farming towns, but the capital <a href="/lore/freimont/city-of-bersau/">City of Bersau</a> is home to a large industrial area.</p><p>Just outside of the city is the Vauxbray Manor - A large estate owned by one of the oldest Frei family, the Vauxbray's, the country's largest crime family organisation.</p><p>East of the city limits is Gensac, one of the primary agriculture towns in Freimont.</p><p>The country was occupied by Helsteig triggering the start of the war. The Richtergard use City of Bersau as a stronghold and forward manufacturing station due to it being a centralised city, and by a great deal, the largest city in Freimont.</p>`,
        image_url: "/images/lore/city-of-bersau/1.png"
    }
]



const FreimontTheCountry = () => (
        <>
            <div className="container">
                <div className="breadcrumbs">
                    <a href="/" className="item">Home</a>
                    <div className="divider"/>
                    <a href="/lore/" className="item">Lore</a>
                    <div className="divider"/>
                    <div className="item">Freimont - The Country</div>
                </div>

                <div className="boxed layout sidebar left">
                    <LoreNavigation page_title="The Country" />
                    <div className="bg">
                        <div className="content">
                            {sections.map(section => (
                                <InfoSection title={section.title} content={section.content} image_url={section.image_url}/>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
;

export default FreimontTheCountry;