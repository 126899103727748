import React from "react";
import InfoSection from "../../../utilities/InfoSection";
import LoreNavigation from "../../../utilities/LoreNavigation";

const sections = [
    {
        title: "The Soldiers of Helsteig",
        content: `<p>Shortened to 'RG', the Richtergard are the fighting force of the <a href="/lore/helsteig/">Helsteig</a>. Experts in both conquering and defending territory, the RG quickly increased Helsteig's expanse, making it extremely difficult for a counter-invasion by their enemies.</p>`,
        image_url: "/images/lore/the-richtergard/1.png"
    }
]

const HelsteigTheRichtergard = () => (
        <>
            <div className="container">
                <div className="breadcrumbs">
                    <a href="/" className="item">Home</a>
                    <div className="divider"/>
                    <a href="/lore/" className="item">Lore</a>
                    <div className="divider"/>
                    <div className="item">Helsteig - The Richtergard</div>
                </div>

                <div className="boxed layout sidebar left">
                    <LoreNavigation page_title="The Richtergard" />
                    <div className="bg">
                        <div className="content">
                            {sections.map(section => (
                                <InfoSection
                                    title={section.title}
                                    content={section.content}
                                    image_url={section.image_url}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
;

export default HelsteigTheRichtergard;