import React from "react";
import InfoSection from "../../utilities/InfoSection";


const sections = [
    {
        title: "Welcome to the Radio Silent Builders Manual.",
        content: `<p>This wiki provides some useful information for best building practises, to ensure you
                            build to the official standards. Think of this as more of a guideline to help
                            consistency between builders based around game design theory and developer
                            standards.</p>
                        <p>Use the sidebar menu to browse the wiki.</p>
                        <p>It is recommended that you read the entire wiki.</p>
                        <p>This wiki will be updated regularly, make sure to check out the discord server
                            announcements for when new updates are live!</p>`,
        image_url: "/images/builders-manual/1.png"
    }, {
        title: "This looks cool! How do I make voxel models?",
        content: `<p>MagicaVoxel is used to create my models, it's a free and simple program to learn.</p><a class="button" href="https://ephtracy.github.io/" target="_blank">Download Magica Voxel</a>`,
    }, {
        title: "Default Magica Voxel scene (includes Palette)",
        content: `<a class="button" href="/downloads/default_2106.vox" download><i class="icon download"></i> Download (26th June 2021)</a>`,
    }, {
        title: "Submit your models",
        content: `<p>I'm accepting submissions and going through these on a live stream, giving
                            feedback/adding them to the world. I'm going to be extremely picky with these
                            models, so please do not expect it to be accepted into the game.</p>
                        <p>Models submitted will be under the Creative Commons license (Attribution CC BY, more
                            info here: https://creativecommons.org/licenses/) which means you will not be
                            financially compensated for your work, but still credited in the game. You'll be
                            given the opportunity to list your credited name upon submission.</p><a
                                target="_blank" href="https://forms.gle/tb9phSxRDwar9Mz18"
                                class="button">Submit</a>`,
    }
]


const BuildersManualIntroduction = () => (
    <div className="container">
        <div className="breadcrumbs">
            <a href="/" className="item">Home</a>
            <div className="divider"/>
            <a href="/builders-manual/" className="item">Builders Manual</a>
            <div className="divider"/>
            <div className="item">Introduction</div>
        </div>

        <div className="boxed layout sidebar left">
            <div>
                <div className="nav-vertical">
                    <div className="item header">Builders Manual</div>
                    <a href="/builders-manual/" className="item active">Introduction</a>
                    <a href="/builders-manual/basic-building-guidelines/" className="item">Basic Building Guidelines</a>
                    <a href="/builders-manual/palette-guide/" className="item">Palette Guide</a>
                </div>

            </div>
            <div className="bg">
                <div className="content">
                    {sections.map(section => (
                        <InfoSection title={section.title} content={section.content} image_url={section.image_url} />
                    ))}
                </div>
            </div>
        </div>
    </div>
);

export default BuildersManualIntroduction;