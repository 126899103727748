import React from "react";
import InfoSection from "../../../utilities/InfoSection";
import LoreNavigation from "../../../utilities/LoreNavigation";

const sections = [
    {
        title: "An Empire",
        content: `<p>Helsteig is a sprawling empire, at its financial and territorial climax, the country yearns for its expanse. For years it has been obstructed with overpopulation. Helsteig is lead by The High Command, a league of eight governors collectively controlling every city in the country.</p><p>Helsteig has an abundance of metal resources - specifically Cloutium. A precious metal used in high value jewellery and new age technology, highly demanded world wide.</p><p>Due to it's large population, Helsteig has a very large military used to police its people, as well as defend and expand its borders with its hostile neighbours.</p>`,
        image_url: "/images/lore/helsteig/1.png",
        secondary_image_url: "/images/lore/helsteig/2.png"
    }
]



const HelsteigAnEmpire = () => (
        <>
            <div className="container">
                <div className="breadcrumbs">
                    <a href="/" className="item">Home</a>
                    <div className="divider"/>
                    <a href="/lore/" className="item">Lore</a>
                    <div className="divider"/>
                    <div className="item">Helsteig - An Empire</div>
                </div>

                <div className="boxed layout sidebar left">
                    <LoreNavigation page_title="An Empire" />
                    <div className="bg">
                        <div className="content">
                            {sections.map(section => (
                                <InfoSection
                                    title={section.title}
                                    content={section.content}
                                    image_url={section.image_url}
                                    secondary_image_url={section.secondary_image_url}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
;

export default HelsteigAnEmpire;