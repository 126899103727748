import React from "react";

class InfoSection extends React.Component {
    constructor({props, title, content, image_url, secondary_image_url}) {
        super(props);
        this.state = {
            title: title,
            content: content,
            image_url: image_url || null,
            secondary_image_url: secondary_image_url || null
        }
    }
    render() {
        return (
            <section>
                <h1>{this.state.title}</h1>
                <div className={this.state.image_url ? 'section-content with-image' : 'section-content'}>
                    <div className="content" dangerouslySetInnerHTML={{__html: this.state.content}} />
                    <div>
                        {this.state.image_url ? <img src={this.state.image_url}/> : ''}
                        {this.state.secondary_image_url ? <img src={this.state.secondary_image_url}/>: ''}
                    </div>
                </div>
            </section>
        )
    }
}

export default InfoSection;