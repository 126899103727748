import React from "react";
import InfoSection from "../../utilities/InfoSection";
import LoreNavigation from "../../utilities/LoreNavigation";

const sections = [
    {
        title: "The Cloutium Train Heist",
        content: `<p>The 1327 train left the capitol of <a href="/lore/helsteig/">Helsteig</a> early one winter morning. Disguised as a maize transport, it was guarded by a small group of <a href="/lore/helsteig/the-richtergard/">Richtergard</a> troops to avoid suspicion. The payload; 93% of the world's excavated Cloutium - a rare, expensive brassy metal. This comprised of almost a fifth of the Helsteig's wealth.<br/><br/>During transit, the train was scheduled to arrive at a station in <a href="/lore/freimont/city-of-bersau/">Bersau</a>, <a href="/lore/freimont/">Freimont</a> by late afternoon to drop off some of its maize cargo. It never arrived.<br/><br/>Once officials were made aware of the incident, a full investigation was launched to find the missing train. No trace of the 1327 train or its cargo was ever found.</p>`,
        image_url: "/images/lore/1.png"
    }, {
        title: "The Richtergard Invasion",
        content: `<p>A train vanishing from thin air with no evidence of it disappearing left officials embarrassed. To shift the blame and distract their people, the <a href="/lore/helsteig">Helsteig</a> officials passed a recommendation to high command to invade <a href="/lore/freimont/">Freimont</a>, as their investigation had no culprit.<br/><br/>Troops were assembled in Helsteig and sent marching into Freimont.</p>`,
        image_url: "/images/lore/2.png"
    }, {
        title: "The Yield of Bersau",
        content: `<p>The Frei troops lacked numbers and equipment. For their people, the <a href="/lore/freimont/city-of-bersau/">City of Bersau</a> was the last stand. The last major foothold the people had. The capital city was the only place the people could seek refuge. Every farm town, every hamlet, and every sanctuary in <a href="/lore/freimont/">Freimont</a> had been overthrown by <a href="/lore/helsteig/the-richtergard/">The Richtergard</a>.<br/><br/>As the troops marched into the city, the people laid down their weapons, for they had already given up hope. Ghettos were erected, buildings were burnt, ministers were executed in the streets. The <a href="/lore/helsteig/">Helsteig</a> High Command were looking for their Cloutium, and would not stop the merciless slaughter until they had found their fortune.</p>`,
        image_url: "/images/lore/3.jpg"
    }, {
        title: "The War",
        content: `<p>After <a href="/lore/freimont/city-of-bersau/">Bersau</a> had fallen, an allied nation, <a href="/lore/the-royal-federation/">The Royal Federation</a> braced their army for war. The Richtergard were remarkably proficient at seizing land, and because of this, The Feds launched an unconventional tactic against the invaders. By holding and bracing the now small land that the Freis still had, they dug in and built large armaments to stage large barrages of artillery to ward off any counter-assault by the RG.<br/><br/>A very cold winder held both sides at a stalemate. But as the ice melted, a new executive was formed.</p>`,
        image_url: "/images/lore/4.jpg"
    }, {
        title: "Sabotage!",
        content: `<p>After the fall of <a href="/lore/freimont/city-of-bersau/">Bersau</a>, intelligence at <a href="/lore/the-royal-federation/">The Royal Federation</a> determined that a myriad of operatives and small strike groups behind the enemies lines had established a network deep within the cities limits, the very heart of the invasion in <a href="/lore/freimont/">Freimont</a>. <br/><br/>After proving their effectiveness in sabotage, these soldiers were appointed their division - The <a href="/lore/the-royal-federation/fuse-freimont-underground-sabotage-executive/">Freimont Underground Sabotage Executive</a>, or FUSE for short.</p>`,
        image_url: "/images/lore/5.png"
    }
]

const LoreIntroduction = () => (
        <>
            <div className="container">
                <div className="breadcrumbs">
                    <a href="/" className="item">Home</a>
                    <div className="divider"/>
                    <a href="/lore/" className="item">Lore</a>
                    <div className="divider"/>
                    <div className="item">Introduction</div>
                </div>

                <div className="boxed layout sidebar left">
                    <LoreNavigation page_title="Timeline" />
                    <div className="bg">
                        <div className="content">
                            {sections.map(section => (
                                <InfoSection title={section.title} content={section.content} image_url={section.image_url}/>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
;

export default LoreIntroduction;