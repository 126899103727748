import React from "react";
import InfoSection from "../../utilities/InfoSection";

const sections = [
    {
        title: "Follow the Palette guide",
        content: `<p>Radio Silent uses a very specific durability based palette system in engine. Make sure you read the palette guide page.</p><a class="button" href="/builders-manual/palette-guide/"><i class="icon palette"></i> View Palette Guide</a>`,
        image_url: "/images/builders-manual/2.png"
    
    }, {
        title: "Remember scale",
        content: `<p>Scale is incredibly important, it creates consistency between models to prevent a model feeling out of place. The default template vox (which can be found in discord) includes the correct palette, notes, and Jacob - a person sized scaler.</p><p>Here's some useful measurements:</p><ul><li>A character is about 16 voxels tall and 6 voxels wide.</li><li>A door is 10x20, door frames are 12x22.</li><li>Each storey of a floor is usually around 24 voxels high</li><li>Train rails are 14 voxels apart (12 voxels between the rails)</li></ul>`
    
    }, {
        title: "Props and Buildings",
        content: `<p>Every kind of build is useful! Don't be afraid of building massive monuments or small props. If it looks great and made realistically, then it'll be used. Submissions are more likely to be chosen if they're buildings, rather than vehicles/weapons/small props.</p>`
    
    }, {
        title: "Build Realistically",
        content: `<p>To make full use of the game's voxel destruction, make sure to build realistically. Think about how structures are made whilst building. Add brickwork in between layers of walls. When a wall is teared down the brickwork will be exposed which will look a lot more realistic. This is a very important part of the game, and if it's not done correctly may lead to a build not being put into the official servers.</p>`
    
    }, {
        title: "Absolutely No 'Banned Imagery': Swastikas, Red Crosses, or Penis'",
        content: `<ul><li>Swastikas are banned in Germany. Don't be an edgy dumbass.</li><li>The Red Cross has banned use of a red cross in video games. It's a dumb rule but something that game developers have to follow.</li><li>Don't build a penis. Nobody thinks its funny.</li><li>Submitting builds with hidden banned imagery will cause you to be blacklisted from submitting content in the future, and possibly banned from discord.</li></ul>`
    
    }, {
        title: "World Districts",
        content: `<p>Think carefully when set dressing about where the location of the building is. If it is a farm town, would it have an expensive looking house? Probably not.</p>`
    
    }, {
        title: "Windows, Doors, Lightbulbs?",
        content: `<p>Windows will be created in engine, this is due to two reasons; firstly voxels cannot be transparent, secondly, the windows will need to be able to be smashed through as a pane of glass which is all handled in the engine.</p><p>Doors are also created in engine using the dimensions mentioned above. This is so they can have gameplay elements, like being locked and have their opening/closing animations.</p><p>Lightbulbs are similar to windows in that they are transparent and need to be able to smash. They also have lighting elements.</p>`
    
    }, {
        title: "Color Theory",
        content: `<p>A major part of the cohesion in building is color theory. Do not edit the voxel palette as it will not appear correct in the game, if you want to learn more about the combination of art and science which is color theory, here's a great video to watch: https://www.youtube.com/watch?v=nIw5Ug1upz4</p>`
    
    }, {
        title: "Use Concept Art",
        content: `<p>Find concept art with google! It really helps to create something when you're looking at images to take inspiration from.</p>`
    
    }, {
        title: "Submit your models",
        content: `<p>I'm accepting submissions and going through these on a live stream, giving feedback/adding them to the world. I'm going to be extremely picky with these models, so please do not expect it to be accepted into the game.</p><p>Models submitted will be under the Creative Commons license (Attribution CC BY, more info here: https://creativecommons.org/licenses/) which means you will not be financially compensated for your work, but still credited in the game. You'll be given the opportunity to list your credited name upon submission.</p>
                    <a target="_blank" href="https://forms.gle/tb9phSxRDwar9Mz18" class="ui button inverted fluid">SUBMIT</a>`,
    }
]

const BuildersManualBasicBuildingGuidelines = () => (
    <div className="container">
        <div className="breadcrumbs">
            <a href="/" className="item">Home</a>
            <div className="divider"/>
            <a href="/builders-manual/" className="item">Builders Manual</a>
            <div className="divider"/>
            <div className="item">Basic Building Guidelines</div>
        </div>

        <div className="boxed layout sidebar left">
            <div>
                <div className="nav-vertical">
                    <div className="item header">Builders Manual</div>
                    <a href="/builders-manual/" className="item">Introduction</a>
                    <a href="/builders-manual/basic-building-guidelines/" className="item active">Basic Building Guidelines</a>
                    <a href="/builders-manual/palette-guide/" className="item">Palette Guide</a>
                </div>

            </div>
            <div className="bg">
                <div className="content">
                    {sections.map(section => (
                        <InfoSection title={section.title} content={section.content} image_url={section.image_url}/>
                    ))}
                </div>
            </div>
        </div>
    </div>
);

export default BuildersManualBasicBuildingGuidelines;