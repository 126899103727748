import React from "react";
import InfoSection from "../../../utilities/InfoSection";
import LoreNavigation from "../../../utilities/LoreNavigation";

const sections = [
    {
        title: "The capitol of Freimont",
        content: `<p>Info coming soon.</p>`,
        image_url: "/images/lore/city-of-bersau/1.png"
    }
]



const FreimontCityOfBersau = () => (
        <>
            <div className="container">
                <div className="breadcrumbs">
                    <a href="/" className="item">Home</a>
                    <div className="divider"/>
                    <a href="/lore/" className="item">Lore</a>
                    <div className="divider"/>
                    <div className="item">Freimont - City of Bersau</div>
                </div>

                <div className="boxed layout sidebar left">
                    <LoreNavigation page_title="City of Bersau" />
                    <div className="bg">
                        <div className="content">
                            {sections.map(section => (
                                <InfoSection title={section.title} content={section.content} image_url={section.image_url}/>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
;

export default FreimontCityOfBersau;