import React from "react";

class LoreNavigation extends React.Component {
    constructor({props, page_title}) {
        super(props);
        this.state = {
            page_title: page_title || null
        }
    }

    render() {
        return (
            <div>
                <div className="nav-vertical">
                    <div className="item header">Lore</div>
                    <a href="/lore/" className={`item ${this.state.page_title === 'Timeline' ? "active" : ""}`}>Timeline</a>
                    <div className="item header">Helsteig</div>
                    <a href="/lore/helsteig/" className={`item ${this.state.page_title === 'An Empire' ? "active" : ""}`}>An Empire</a>
                    <a href="/lore/helsteig/the-richtergard/" className={`item ${this.state.page_title === 'The Richtergard' ? "active" : ""}`}>The Richtergard</a>
                    <div className="item header">Freimont</div>
                    <a href="/lore/freimont/" className={`item ${this.state.page_title === 'The Country' ? "active" : ""}`}>The Country</a>
                    <a href="/lore/freimont/city-of-bersau/" className={`item disabled ${this.state.page_title === 'City of Bersau' ? "active" : ""}`}>City of Bersau <span className="note">coming soon</span></a>
                    <div className="item header">The Royal Federation</div>
                    <a href="/lore/the-royal-federation/" className={`item ${this.state.page_title === 'Who are they?' ? "active" : ""}`}>Who are they?</a>
                    <a href="/lore/the-royal-federation/fuse-freimont-underground-sabotage-executive/" className={`item ${this.state.page_title === 'F.U.S.E.' ? "active" : ""}`}>F.U.S.E.</a>
                </div>
            </div>
        )
    }
}

export default LoreNavigation;