import React from "react";
import InfoSection from "../../../utilities/InfoSection";
import LoreNavigation from "../../../utilities/LoreNavigation";

const sections = [
    {
        title: "Freimont Underground Sabotage Executive",
        content: `<p>The FUSE is a special auxiliary unit comprises of escaped POW's, partisan soldiers, strike groups lost behind enemy lines, and Paratroopers. It is a covert fighting executive set up to sabotage the enemy from deep behind the front line led by <a href="/lore/the-royal-federation/">The Royal Federation</a>.</p><p>The group was first established some time after the Yield of <a href="/lore/freimont/city-of-bersau/">Bersau</a>, at the beginning of the war, when operatives were stranded and cut off. After conducting surveillance missions, the then unnamed group of soldiers received orders from the Royals to expand their operations to conduct raids, sabotage missions, and assassinations.</p><p>After many successful but arduous months, FUSE received reinforcements from the Royal Federation. After Bolstering their ranks, FUSE set out to bring down the <a href="/lore/helsteig/the-richtergard/">Richtergard</a> occupation of <a href="/lore/freimont/">Freimont</a> from within the border.</p>`,
        image_url: "/images/lore/fuse/1.png"
    }
]



const RoyalFederationFUSE = () => (
        <>
            <div className="container">
                <div className="breadcrumbs">
                    <a href="/" className="item">Home</a>
                    <div className="divider"/>
                    <a href="/lore/" className="item">Lore</a>
                    <div className="divider"/>
                    <div className="item">The Royal Federation - F.U.S.E.</div>
                </div>

                <div className="boxed layout sidebar left">
                    <LoreNavigation page_title="F.U.S.E." />
                    <div className="bg">
                        <div className="content">
                            {sections.map(section => (
                                <InfoSection title={section.title} content={section.content} image_url={section.image_url}/>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
;

export default RoyalFederationFUSE;