import React from "react";
import InfoSection from "../../../utilities/InfoSection";
import LoreNavigation from "../../../utilities/LoreNavigation";

const sections = [
    {
        title: "A country fighting for Freimont's freedom",
        content: `<p>The Royal Federation came to <a href="/lore/freimont/">Freimont</a>'s aid after the fall of <a href="/lore/freimont/city-of-bersau/">Bersau</a>. A peace treaty between <a href="/lore/helsteig/">Helsteig</a> and Freimont was presented to the Helsteig high command to reinstate order and overturn the invasion, but the pleas for peace were ignored by the ruthless invaders.</p><p>As the Helsteig reach approached The Royal Federation's borders, and with Bersau fallen into <a href="/lore/helsteig/the-richtergard/">Richtergard</a> hands, The Fed's had no choice but to counter attack.</p><p>The Royal Federation used Helsteig's sprawling empire to their advantage in the war, sending soldiers deep behind enemy lines via their poorly protected fronts. The Result of which had many stranded, captured, or dead. Very quickly, <a href="/lore/the-royal-federation/fuse-freimont-underground-sabotage-executive/">FUSE</a> was created to utilize these soldiers and destabilize the invasion from within.</p>`,
        image_url: "/images/lore/city-of-bersau/1.png"
    }
]



const RoyalFederationWhoAreThey = () => (
        <>
            <div className="container">
                <div className="breadcrumbs">
                    <a href="/" className="item">Home</a>
                    <div className="divider"/>
                    <a href="/lore/" className="item">Lore</a>
                    <div className="divider"/>
                    <div className="item">The Royal Federation - Who are they?</div>
                </div>

                <div className="boxed layout sidebar left">
                    <LoreNavigation page_title="Who are they?" />
                    <div className="bg">
                        <div className="content">
                            {sections.map(section => (
                                <InfoSection title={section.title} content={section.content} image_url={section.image_url}/>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
;

export default RoyalFederationWhoAreThey;