import React from "react";
import {Switch} from "react-router";
import {Route, BrowserRouter as Router } from "react-router-dom";
import {Helmet} from "react-helmet";
import LandingPage from "./pages/LandingPage";
import BuildersManualIntroduction from "./pages/builders-manual/introduction";
import BuildersManualBasicBuildingGuidelines from "./pages/builders-manual/basic-guidelines";
import BuildersManualPaletteGuide from "./pages/builders-manual/palette-guide";
import LoreIntroduction from "./pages/lore/introduction";
import HelsteigAnEmpire from "./pages/lore/helsteig/empire";
import HelsteigTheRichtergard from "./pages/lore/helsteig/richtergard";
import FreimontTheCountry from "./pages/lore/freimont/country";
import FreimontCityOfBersau from "./pages/lore/freimont/bersau";
import RoyalFederationFUSE from "./pages/lore/royal-federation/fuse";
import RoyalFederationWhoAreThey from "./pages/lore/royal-federation/whoarethey";

const error = () => (
    <div className="container">
        <div className="boxed layout error-page">
            <h3>Soldier, you've gone too far behind enemy lines.</h3>
            <p>All communication has been lost.</p>
            <br/>
            <br/>
            <br/>
            <h1 style={{fontSize: "100pt"}}>404</h1>
            <a href="/" className="button">Go back to the homepage</a>
        </div>
    </div>
);

function App() {
    return (
        <Router>
            <Helmet>
                <title>Radio Silent | .-. . -.. .- -.-. - . -..</title>
                <meta name="description" content="Radio Silent is a multiplayer co-operative stealth shooter set in a WW2-era fictional entirely destructible open world." />
            </Helmet>
            <div className="container">
                <nav>
                    <a href="/" className={`item ${window.location.pathname === '/' ? "active" : ""}`}>Home</a>
                    <a href="/lore/" className={`item ${window.location.pathname.startsWith('/lore/') ? "active" : ""}`}>Lore</a>
                    <a href="/builders-manual/" className={`item ${window.location.pathname.startsWith('/builders-manual/') ? "active" : ""}`}>Builders Manual</a>

                    <div className="nav right">
                        <a href="https://discord.gg/N9DsUnmaxj" target="_blank" className="item" rel="noreferrer"><i className="fab fa-discord"></i></a>
                        <a href="https://www.reddit.com/r/Radiosilent/" target="_blank" className="item" rel="noreferrer"><i className="fab fa-reddit"></i></a>
                        <a href="https://twitter.com/maxhayon" target="_blank" className="item" rel="noreferrer"><i className="fab fa-twitter"></i></a>
                        <a href="https://www.youtube.com/channel/UC6cFCqqmIzv0ggsn9YhSUIQ" target="_blank" className="item" rel="noreferrer"><i className="fab fa-youtube"></i></a>
                    </div>
                </nav>

                <Switch>
                    <Route path='/' exact component={LandingPage}/>
                    <Route path='/lore/' exact component={LoreIntroduction}/>
                    <Route path='/lore/helsteig/' exact component={HelsteigAnEmpire}/>
                    <Route path='/lore/helsteig/the-richtergard/' exact component={HelsteigTheRichtergard}/>
                    <Route path='/lore/freimont/' exact component={FreimontTheCountry}/>
                    <Route path='/lore/freimont/city-of-bersau/' exact component={FreimontCityOfBersau}/>
                    <Route path='/lore/the-royal-federation/' exact component={RoyalFederationWhoAreThey}/>
                    <Route path='/lore/the-royal-federation/fuse-freimont-underground-sabotage-executive/' exact component={RoyalFederationFUSE}/>
                    <Route path='/builders-manual/' exact component={BuildersManualIntroduction}/>
                    <Route path='/builders-manual/basic-building-guidelines/' exact component={BuildersManualBasicBuildingGuidelines}/>
                    <Route path='/builders-manual/palette-guide/' exact component={BuildersManualPaletteGuide}/>
                    <Route component={error}/>
                </Switch>
            </div>
        </Router>
    );
}

export default App;
