import React from "react";

const LandingPage = () => (
    <>
        <div className="container">
            <div className="boxed layout">
                <div className="content" style={{padding: "16px"}}>
                    <iframe width="100%" height="720px" src="https://www.youtube-nocookie.com/embed/eOCyJwcwwsQ"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen/>
                </div>
            </div>
        </div>
    </>
)
;

export default LandingPage;