import React from "react";
import InfoSection from "../../utilities/InfoSection";

const sections = [
    {
        title: "How does the palette work?",
        content: `<p>In Radio Silent, each color (or index) represents a different type of voxel in the game.</p><p>These are standard RGB colors, which means they cannot have additional properties like metallic, roughness, transparency, or emissive. If you want to add lights or glass, they will be added in in the engine.</p><p>Each index is separated into groups, these are durability tiers. Each tier has a different durability response in the world, for example - a low calibre pistol round might be able to pierce voxels made from tier 1 and 2, while a higher calibre rifle round might be able to pierce tier 1,2, and 3. Its vitally important you keep this in mind when choosing which color to use, as heavy metal(T4) is vastly different to light metal(T3). Heavy metal will be used for reinforced armoured doors and tank plating, whilst light metal will be used for things like tin roofing, street signs, drain pipes.</p><p>Other than choosing the right tier, choosing the right material is extremely important.</p><p>Sound effects are inherited from color indices - this means when something in the 'Wood/Colored Wood/ Dark Colored Wood' section is shot, it will play a wooden sound effect, the same goes for if it is stepped on, it will make a wooden step sound. NEVER use a material for anything other than its intended purpose. If you feel like a material sound is missing please let me know, which brings me onto my next point</p><p>The Palette is always changing - I'm updating it about one every two weeks / a month. The majority of the time, the colors will not be moved around, new ones will be added. You may have to go back and change a model only if it was using a material index that it is using is moved. This is quite rare.</p><p>Other updates to the palette will mainly be adding new materials and also toning old ones depending on how they fit in the scene. Its always useful to be on the latest palette to get new materials.</p>`,
        image_url: "/images/builders-manual/palette-2104.png"
    
    }
]

const BuildersManualPaletteGuide = () => (
    <div className="container">
        <div className="breadcrumbs">
            <a href="/" className="item">Home</a>
            <div className="divider"/>
            <a href="/builders-manual/" className="item">Builders Manual</a>
            <div className="divider"/>
            <div className="item">Palette Guide</div>
        </div>

        <div className="boxed layout sidebar left">
            <div>
                <div className="nav-vertical">
                    <div className="item header">Builders Manual</div>
                    <a href="/builders-manual/" className="item">Introduction</a>
                    <a href="/builders-manual/basic-building-guidelines/" className="item">Basic Building Guidelines</a>
                    <a href="/builders-manual/palette-guide/" className="item active">Palette Guide</a>
                </div>

            </div>
            <div className="bg">
                <div className="content">
                    {sections.map(section => (
                        <InfoSection title={section.title} content={section.content} image_url={section.image_url}/>
                    ))}
                </div>
            </div>
        </div>
    </div>
);

export default BuildersManualPaletteGuide;